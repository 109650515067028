import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SideBar from "../components/sideBar"
import Image from "../components/image"
import MaterialTable from 'material-table';

import { layoutStyles } from '../style/layoutStyles';
import { MaterialTableStyle } from "../style/materialTableStyle"

export default ({ pageContext }) => {
  var newsArticle = [];

  newsArticle =pageContext.allNews;

    return (
    <Layout>
        <SEO title={"Latest News"} />
        <div css={layoutStyles} id="content">
        <div className="grid">
          <div className="content col-1-1">
            <div className="masthead">
                <div className="masthead col-1-1">
                <Image scr={[]} Mode="port"/>  
                </div>
            </div>
          </div>
            <div id="sectionPage" className="col-1-1">
              <div id="article" className="col-9-12">
                <h1>Latest News</h1>
                <div css={MaterialTableStyle} >
                  <div id="newsLanding">
                  <MaterialTable
                    title="News Archive"
                    columns={[
                    { title: 'Date', field: 'dateFormatShort',render: rowData => rowData.uri.indexOf("jerseyairport")=== -1 ? <Link to={rowData.uri}>{rowData.dateFormatShort}</Link> : <a href={rowData.uri}>{rowData.dateFormatShort}</a>},
                    { title: 'Title', field: 'title', render: rowData => rowData.uri.indexOf("jerseyairport")=== -1 ? <Link to={rowData.uri} dangerouslySetInnerHTML={{ __html: rowData.title }}></Link> : <a href={rowData.uri} dangerouslySetInnerHTML={{ __html: rowData.title }}></a>},
                      ]
                    }
                    data={newsArticle}
                    options={{
                      search: true,
                      pageSize: 15,
                      pageSizeOptions:[15, 30, 100],
                      showTitle: false,
                      
                      rowStyle: rowData => ({ 
                        backgroundImage: 'url('+rowData.backgroundImage+')',
                        backgroundRepeat: 'no-repeat',
                        paddingLeft:20

                      })
                      ,headerStyle: {
                        display: 'none'
                        
                      }
                    }}
                  />
                  </div>
                </div>
              </div>

              <div id="aside" className="col-3-12">
                <SideBar FullWidth={true} />
              </div>

          </div>
        </div>
        <div className="whitespacer"></div>
      </div>

    </Layout>
  )
}